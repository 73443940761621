* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
}

html, body, #root {
  height: 100%;
  overflow: hidden;
}

.titles {
  width: 650px;
  height: 100px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  color: #08131E;
}

.paragraphs {
  width: 800px;
  height: 250px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;

  color: #08131E;
}

.devs-div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
}

.github-p {
  font-style: italic;
  font-size: 20px;
}

.project-title {
  height: auto;
  font-size: 30px;
}

.project-paragraph {
  height: inherit;
  font-size: 20px;
}

.dev-paragraph {
  height: inherit;
  font-size: 20px;
  width: 650;
  text-align: justify;
}

.big-title {
  height: 150px;
}

.dev-title {
  height: auto;
  width: auto;
  font-size: 30px;
}

.tiny-paragraph {
  height: 180px;
}

.contact-paragraph {
  height: auto;
  color: #f0f0f0;
  margin-bottom: 1rem;
}

.white {
  color: #f0f0f0
}

.title-box {
  height: '100%';
  display: flex;
  flex-direction: column;
  gap: 10px
}

.App {
  height: 100vh;
  overflow-y: auto; 
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.section-logo, .section-presentation, .section-project, .section-contact{
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
}

.section-contact {
  background-color: #08131E;
  color: white;
}

.section-logo {
  background: #08131E;
  color: white;
  font-size: 2rem;
  animation: fadeInSmooth 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.section-devs {
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  margin-bottom: 4rem;
}

.section-presentation {
  background: #f0f0f0;
  font-size: 2rem;
}


.section-project {
  background-color: #08131E;
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  z-index: -1;
  pointer-events: none; 
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: white;
  border-radius: 50%;
  animation: starAnimation 3s infinite ease-in-out;
}


@keyframes starAnimation {
  0% {
    transform: scale(0.5);
    opacity: 0.6;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0.6;
  }
}

@keyframes fadeInSmooth {
  from {
    opacity: 0;
    transform: translateY(80px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .titles {
    width: 100%;
    height: inherit;
    font-size: 36px;
  }

  .paragraphs {
    width: 100%;
    height: auto;
    text-align: justify;
    font-size: 18px;
  }

  .image {
    height: auto;
    margin-top: 2rem;
    width: 420px;
  }

  .github-p, .project-paragraph, .dev-paragraph, .contact-paragraph {
    font-size: 16px;
  }

  .project-title, .dev-title {
    font-size: 24px;
  }

  .section-logo, .section-presentation, .section-project, .section-contact, .section-devs {
    height: 100vh;
    padding: 2rem 1rem;
    text-align: center;
  }

  .section-devs {
    height: 200vh;
  }
}